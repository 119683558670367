import React from 'react'
import DefaultPage from '@/layouts/DefaultPage'
import { MFOPage } from '@/containers/MFOPage'
import { AppPage } from '@/models/AppPage'

const Page: AppPage = () => {
  return (
    <DefaultPage hideMobileHeader>
      <MFOPage />
    </DefaultPage>
  )
}

export default Page

import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const Wrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const MFOSlideShimmer = styled(Shimmer)(({ theme }) => ({
  borderRadius: 16,
  width: '100%',
  margin: '32px auto 46px',
  padding: 16,
  background: theme.color['surface/tertiary'],

  [theme.media.md]: {
    padding: 32,
  },
}))

export const MFOSlideTitleShimmer = styled(Shimmer)(({ theme }) => ({
  borderRadius: 16,
  height: 32,
  width: 193,
  background: theme.color['surface/bravo'],

  [theme.media.md]: {
    borderRadius: 20,
    height: 40,
    width: 249,
  },
}))

export const MFOSlideDescShimmer = styled(Shimmer)(({ theme }) => ({
  background: theme.color['surface/bravo'],
  marginTop: 16,
  borderRadius: 8,
  height: 16,
  width: 208,
}))

export const ProgressBarLeftTextShimmer = styled(Shimmer)(({ theme }) => ({
  background: theme.color['surface/bravo'],
  marginTop: 72,
  borderRadius: 8,
  height: 16,
  width: 152,

  [theme.media.md]: {
    marginTop: 62,
  },
}))

export const ProgressBarRightTextShimmer = styled(Shimmer)(({ theme }) => ({
  background: theme.color['surface/bravo'],
  marginTop: 72,
  borderRadius: 8,
  height: 16,
  width: 91,

  [theme.media.md]: {
    marginTop: 62,
  },
}))

export const ProgressBarInnerShimmer = styled(Shimmer)(({ theme }) => ({
  width: '100%',
  height: 8,
  background: theme.color['surface/bravo'],
  borderRadius: 4,
  marginTop: 12,
}))

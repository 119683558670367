import React from 'react'
import { ProductInDevelopmentDialog } from '@/features/product-lists/components/ProductInDevelopmentDialog'

export interface DialogPaymentScheduleProps {
  isOpen?: boolean
  onClose?: () => void
}

export const DialogPaymentSchedule = ({ isOpen, onClose }: DialogPaymentScheduleProps) => {
  return <ProductInDevelopmentDialog isOpen={isOpen} onClose={onClose} />
}

import React from 'react'
import {
  NextPaymentShimmerWrapper,
  NextPaymentDateDescShimmer,
  NextPaymentDateTitleShimmer,
  NextPaymentTitleShimmer,
  NextPaymentWidgetButtonShimmer,
  NextPaymentWidgetContent,
  NextPaymentWidgetDescShimmer,
  NextPaymentWidgetShimmer,
  NextPaymentWidgetTitleShimmer,
} from './styled'

const CONTENT_BLOCK_COUNTER = 3

export const NextPaymentShimmer = () => {
  return (
    <>
      <NextPaymentShimmerWrapper>
        <NextPaymentTitleShimmer />
        <NextPaymentDateTitleShimmer />
        <NextPaymentDateDescShimmer />
      </NextPaymentShimmerWrapper>

      <NextPaymentWidgetShimmer>
        <NextPaymentWidgetContent>
          {Array.from({ length: CONTENT_BLOCK_COUNTER }).map((_, number) => (
            <div key={number}>
              <NextPaymentWidgetDescShimmer isLastBlock={number === CONTENT_BLOCK_COUNTER - 1} />
              <NextPaymentWidgetTitleShimmer isLastBlock={number === CONTENT_BLOCK_COUNTER - 1} />
            </div>
          ))}
        </NextPaymentWidgetContent>

        <NextPaymentWidgetButtonShimmer />
      </NextPaymentWidgetShimmer>
    </>
  )
}

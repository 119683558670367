import React from 'react'
import { InfoWrapper, Title, Message } from './styled'

export const InfoPlate = () => {
  return (
    <InfoWrapper>
      <Title>Информация</Title>
      <Message>Документы отправлены в банк</Message>
    </InfoWrapper>
  )
}

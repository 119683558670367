import React from 'react'
import {
  PaymentScheduleShimmerIcon,
  PaymentScheduleShimmerItem,
  PaymentScheduleShimmerText,
  PaymentScheduleShimmerWrapper,
} from './styled'

export const PaymentScheduleShimmer = () => {
  return (
    <PaymentScheduleShimmerWrapper>
      <PaymentScheduleShimmerItem>
        <PaymentScheduleShimmerIcon />
        <PaymentScheduleShimmerText />
      </PaymentScheduleShimmerItem>
    </PaymentScheduleShimmerWrapper>
  )
}

import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus, useAppContext } from '@/core'
import _keyBy from 'lodash/keyBy'
import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { AppContext } from '@/models/AppContext'
import { useGetContractInfoMFOQuery } from '@/api/products/getContractInfoMFO/getContractInfoMFO'
import { Rules } from '@/models/Rules'

interface State {
  contractsInfoMFO: UseQueryResult<
    GetContractInfoMFOModel.ResponseData,
    GetContractInfoMFOModel.ErrorResponse
  >
}

const selector = createSelector(
  (state: State) => state.contractsInfoMFO.data,
  (state: State) => state.contractsInfoMFO.status,
  (state: State) => state.contractsInfoMFO.refetch,
  (
    productsMFOResponse,
    productsMFOStatus,
    productsMFORefetch
  ): {
    error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null
    status: ApiStatus
    retry: () => void
    contracts: Record<string, GetContractInfoMFOModel.Contract>
  } => {
    const retryFns: (() => void)[] = []

    let contracts: GetContractInfoMFOModel.Contract[] = []

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (productsMFOStatus === 'success' || productsMFOStatus === 'error') {
      status = ApiStatus.Fulfilled
      retryFns.push(productsMFORefetch)
    }

    if (productsMFOStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (productsMFOStatus === 'error') {
      hasNetworkError = true
    }

    if (productsMFOResponse && productsMFOResponse.type === ResponseType.ResolveWithData) {
      contracts = productsMFOResponse.Contracts.filter(
        (product) => product.status !== GetContractInfoMFOModel.StatusCode.NotSet
      ) as GetContractInfoMFOModel.Contract[]
    }

    const error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null = hasNetworkError
      ? {
          type: 'NETWORK',
          message: 'При получении данных произошла ошибка',
        }
      : status === ApiStatus.Fulfilled && !contracts.length
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    const contractsMap = _keyBy(contracts, 'contractId')

    return {
      error: error,
      contracts: contractsMap,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export const useGetMfoProductInfoSelector = () => {
  const context = useAppContext<AppContext>()

  const contractsInfoMFO = useGetContractInfoMFOQuery({
    enabled: context.auth.rules.has(Rules.Access),
  })

  return selector({ contractsInfoMFO })
}

import React from 'react'
import {
  WidgetWrapper,
  WidgetWrapperInner,
  WidgetContent,
  WidgetTitle,
  WidgetDesc,
  PaymentButton,
} from './styled'
import { Currency } from '@/components/Currency'

export interface NextPaymentWidgetProps {
  deductedPayment: number
  wallet: number
  remainingDebt?: number
  isRepaymentCompleted: boolean
  isPaymentDebitedToday: boolean
  buttonClick: () => void
  isEarlyRepayment: boolean
}

export const NextPaymentWidget = ({
  deductedPayment,
  wallet,
  remainingDebt,
  isRepaymentCompleted,
  isPaymentDebitedToday,
  buttonClick,
  isEarlyRepayment,
}: NextPaymentWidgetProps) => {
  const paymentAmount = remainingDebt - wallet > 0 ? remainingDebt - wallet : 0

  return (
    <WidgetWrapper>
      <WidgetWrapperInner>
        <WidgetContent isRepaymentCompleted={isRepaymentCompleted}>
          <WidgetDesc>Будет списано</WidgetDesc>
          <WidgetTitle color="text/main">
            {isPaymentDebitedToday ? (
              <>Скоро расчитаем</>
            ) : (
              <Currency amount={deductedPayment} isShowPrecisionWithNullCents />
            )}
          </WidgetTitle>
        </WidgetContent>

        <WidgetContent order={1} isRepaymentCompleted={isRepaymentCompleted}>
          <WidgetDesc>Собственные средства</WidgetDesc>
          <WidgetTitle color="text/main">
            <Currency amount={wallet} isShowPrecisionWithNullCents />
          </WidgetTitle>
        </WidgetContent>

        {!isRepaymentCompleted && (
          <WidgetContent>
            <WidgetDesc>Осталось доплатить</WidgetDesc>
            <WidgetTitle color="text/main">
              <Currency amount={paymentAmount} isShowPrecisionWithNullCents />
            </WidgetTitle>
          </WidgetContent>
        )}
      </WidgetWrapperInner>

      {!isEarlyRepayment && (
        <PaymentButton isFullWidth onClick={buttonClick}>
          Пополнить
          {!isRepaymentCompleted && paymentAmount > 0 && (
            <>
              {' '}
              на <Currency amount={paymentAmount} />
            </>
          )}
        </PaymentButton>
      )}
    </WidgetWrapper>
  )
}

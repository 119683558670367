import styled from '@emotion/styled'
import { Headline24, Body16 } from '@platform-ui/typography'

export const Title = styled(Headline24)(({ theme }) => ({
  marginBottom: 16,
  color: theme.color[`text/main`],
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: -0.3,

  [`${theme.media.md}`]: {
    lineHeight: '28px',
  },
}))

export const Message = styled(Body16)(({ theme }) => ({
  color: theme.color[`text/main`],
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '18px',
  letterSpacing: 0,

  [`${theme.media.md}`]: {
    lineHeight: '24px',
  },
}))

export const InfoWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color['surface/tertiary'],
  borderRadius: 16,
  padding: 16,
  marginTop: 24,
  marginBottom: 24,
  width: '100%',

  [`${theme.media.md}`]: {
    marginTop: 40,
    padding: 24,
    borderRadius: 20,
    marginBottom: 40,
  },
}))

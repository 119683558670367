import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const NextPaymentShimmerWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: theme.color['surface/tertiary'],
    borderRadius: 12,
    padding: 16,
    marginTop: 24,

    [`${theme.media.md}`]: {
      marginTop: 40,
      padding: 24,
    },
  }
})

export const NextPaymentTitleShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 12,
    height: 24,
    width: 200,
    marginBottom: 16,

    [`${theme.media.md}`]: {
      width: 280,
      marginBottom: 24,
    },
  }
})

export const NextPaymentDateTitleShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 6,
    height: 12,
    width: 100,
    marginBottom: 8,

    [`${theme.media.md}`]: {
      width: 96,
    },
  }
})

export const NextPaymentDateDescShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 10,
    height: 20,
    width: 200,

    [`${theme.media.md}`]: {
      borderRadius: 12,
      height: 24,
      width: 250,
    },
  }
})

export const NextPaymentWidgetShimmer = styled.div(({ theme }) => {
  return {
    borderRadius: 12,
    backgroundColor: theme.color['surface/tertiary'],
    padding: 16,
    marginTop: 16,
    marginBottom: 24,

    [`${theme.media.md}`]: {
      padding: 24,
      marginBottom: 40,
    },
  }
})

export const NextPaymentWidgetContent = styled.div(({ theme }) => {
  return {
    display: 'flex',
    flexWrap: 'wrap',

    [`& > div`]: {
      marginRight: 40,
    },

    [`${theme.media.md}`]: {
      [`& > div:last-of-type`]: {
        marginRight: 0,
        marginLeft: 'auto',
      },
    },
  }
})

export const NextPaymentWidgetDescShimmer = styled(Shimmer)<{ isLastBlock: boolean }>(
  ({ theme, isLastBlock }) => {
    return {
      borderRadius: 6,
      height: 12,
      width: 100,
      marginBottom: 8,

      [`${theme.media.md}`]: {
        width: isLastBlock ? 160 : 100,
      },
    }
  }
)

export const NextPaymentWidgetTitleShimmer = styled(Shimmer)<{ isLastBlock: boolean }>(
  ({ theme, isLastBlock }) => {
    return {
      borderRadius: 12,
      height: 24,
      width: 110,
      marginBottom: 20,

      [`${theme.media.md}`]: {
        marginBottom: 24,
        marginLeft: isLastBlock ? 'auto' : 0,
      },
    }
  }
)

export const NextPaymentWidgetButtonShimmer = styled(Shimmer)(() => {
  return {
    borderRadius: 12,
    height: 56,
  }
})

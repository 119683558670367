import styled from '@emotion/styled'
import { Headline20, Headline28, Body14 } from '@platform-ui/typography'
import { IconWrapper } from '@/components/IconWrapper'
import { InfoIcon } from '@platform-ui/icons'

export const PaymentWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color['surface/secondary'],
  borderRadius: 16,
  padding: 12,

  [`${theme.media.md}`]: {
    padding: 20,
  },
}))

export const PaymentTitleWrapper = styled.div(({ theme }) => ({
  marginBottom: 16,
}))

export const PaymentTitle = styled(Headline28)(({ theme }) => ({
  fontSize: 20,
  weight: 700,
  lineHeight: '24px',
  letter: -0.3,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',

  [`${theme.media.md}`]: {
    fontSize: 28,
    letter: -0.5,
  },
}))

export const DateWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const DateTitle = styled(Body14)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.color['text/secondary'],
  marginBottom: 4,

  [`${theme.media.md}`]: {
    fontWeight: 600,
    marginBottom: 8,
  },
}))

export const DateDesc = styled(Headline20)(({ theme }) => ({
  color: theme.color['text/main'],
  fontSize: 16,
  marginRight: 35,
  weight: 600,
  lineHeight: '24px',
  letter: -0.3,

  [`${theme.media.md}`]: {
    fontSize: 20,
    marginRight: 35,
    weight: 700,
    lineHeight: '28px',
  },
}))

export const PaymentDate = styled(Headline20)(() => ({
  fontSize: 16,
  marginRight: 35,
}))

export const DateIconWrapper = styled(IconWrapper)(({ theme }) => ({
  width: 32,
  height: 32,
  marginRight: 16,

  [`${theme.media.md}`]: {
    width: 40,
    height: 40,
  },
}))

export const Info = styled(InfoIcon)(({ theme }) => ({
  width: '22px',
  height: '22px',
  borderRadius: theme.borderRadius.r10,
  backgroundColor: theme.color['icons/secondary'],
  color: theme.color['text/tertiary'],
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}))

export const TooltipBody = styled.div(({ theme }) => ({
  padding: `${theme.spacing.s4} ${theme.spacing.s6}`,
  maxWidth: '500px',
}))

import React, { useEffect } from 'react'
import { useRouter } from '@/next/router'
import { ContentHeader } from '@/components/ContentHeader'
import { ContentError } from '@/components/ContentError'
import { ApiStatus, useAppContext } from '@/core'
import { MFOSlider } from '@/features/mfo-slider'
import { AppContext } from '@/models/AppContext'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { useGetMfoProductInfoSelector } from './selectors/useGetMfoProductInfoSelector'
import { MFODetails } from '@/features/mfo-details'

const getTitle = (type: string, interestRate: number, partnerName: string): string => {
  switch (type) {
    case 'homerContract':
      return `Заём на товар ${interestRate}%`
    case 'homerCash':
      return `Заём наличными ${interestRate}%`
    default:
      return partnerName
  }
}

const getHeaderDescription = (type: string, interestRate: number): string | null => {
  if (type === 'homerContract' || type === 'homerCash') {
    return null
  }

  return `Заём ${interestRate}%`
}

export const MFOPage = () => {
  const router = useRouter()
  const context = useAppContext<AppContext>()
  const productId = router.query.productId as string

  const { status, contracts, retry } = useGetMfoProductInfoSelector()
  const { data } = useSettingsQuery()

  const enableProductsMfo =
    data && data.type === ResponseType.ResolveWithData && data.result.enableProductsMfo

  const isLoading = status === ApiStatus.Pending
  const isServerError = status === ApiStatus.Rejected
  const isProductNotFound = status === ApiStatus.Fulfilled && !contracts[productId]

  useEffect(() => {
    if (!enableProductsMfo && data?.type === ResponseType.ResolveWithData) {
      router.push(context.routes.home())
    }
  }, [enableProductsMfo])

  if ((!contracts[productId] && !isLoading) || isServerError || isProductNotFound) {
    return <ContentError />
  }

  const contract = contracts[productId]
  const title = getTitle(
    contract?.type,
    contract?.interestRate || 0,
    contract?.partnerInfo?.PartnerName || ''
  )
  const description = getHeaderDescription(contract?.type, contract?.interestRate || 0)

  return (
    <>
      <ContentHeader isLoading={isLoading} title={title} description={description} />
      <MFOSlider contract={contract} status={status} />
      <MFODetails contract={contract} status={status} retry={retry} />
    </>
  )
}

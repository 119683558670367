import React from 'react'
import { TimeInstallmentIcon } from '@platform-ui/icons'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { PaymentScheduleWrapper, Title, Wrapper } from './styled'

export type TabListProps = {
  onOpen: () => void
}

export const PaymentSchedulePlate = ({ onOpen }: TabListProps) => {
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile

  return (
    <PaymentScheduleWrapper onClick={onOpen}>
      <Wrapper>
        <TimeInstallmentIcon color={'icons/primary'} size={isMobile ? 's12' : 's16'} />
        <Title>График</Title>
      </Wrapper>
    </PaymentScheduleWrapper>
  )
}

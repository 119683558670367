import styled from '@emotion/styled'
import { Body14, Headline32 } from '@platform-ui/typography'

export const MFOSliderWrapper = styled.div(({ theme }) => ({
  marginTop: 16,
  marginBottom: 24,

  [theme.media.md]: {
    marginTop: 32,
    marginBottom: 40,
  },
}))

export const MFOSlide = styled.div<{ bgColor: string }>(({ theme, bgColor }) => ({
  borderRadius: theme.borderRadius.r14,
  background: theme.color[bgColor] || bgColor,
  height: 200,
  maxWidth: 656,
  padding: 16,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.media.md]: {
    height: 234,
    padding: 32,
  },
}))

export const MFOSlideTitle = styled(Headline32)(({ theme }) => ({
  fontWeight: 700,
  color: theme.color['text/tertiary'],

  [theme.media.md]: {
    fontSize: 40,
    lineHeight: '48px',
    letterSpacing: '-0.6px',
  },
}))

export const MFOSlideDesc = styled(Body14)(({ theme }) => ({
  color: theme.color['text/tertiaryTransparent'],
  marginTop: 8,
}))

export const PartnerIcon = styled.img(({ theme }) => ({
  height: 12,

  [theme.media.md]: {
    height: 24,
  },
}))

export const ProgressBarTextWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
}))

export const ProgressBarText = styled(Body14)(({ theme }) => ({
  color: theme.color['text/tertiaryTransparent'],
}))

export const ProgressBar = styled.div(({ theme }) => ({
  width: '100%',
  height: 8,
  background: theme.color['surface/lightLine'],
  borderRadius: 8,
}))

export const ProgressBarInner = styled.div<{ completed: number }>(({ theme, completed }) => ({
  width: `${completed}%`,
  height: '100%',
  background: theme.color['surface/main'],
  borderRadius: 8,
}))

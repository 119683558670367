import React from 'react'
import {
  Wrapper,
  MFOSlideShimmer,
  MFOSlideDescShimmer,
  MFOSlideTitleShimmer,
  ProgressBarInnerShimmer,
  ProgressBarLeftTextShimmer,
  ProgressBarRightTextShimmer,
} from './styled'

export const MFOSliderShimmer = () => {
  return (
    <MFOSlideShimmer>
      <div>
        <MFOSlideTitleShimmer />
        <MFOSlideDescShimmer />
      </div>
      <Wrapper>
        <ProgressBarLeftTextShimmer />
        <ProgressBarRightTextShimmer />
      </Wrapper>
      <ProgressBarInnerShimmer />
    </MFOSlideShimmer>
  )
}

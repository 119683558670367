import styled from '@emotion/styled'
import { Headline14, Headline20 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'

export const WidgetWrapper = styled.div(({ theme }) => ({
  padding: 16,
  background: theme.color['surface/secondary'],
  borderRadius: 20,
  minWidth: 212,
  marginTop: 16,
  marginBottom: 32,

  [`${theme.media.md}`]: {
    padding: 32,
    minWidth: 360,
    marginTop: 16,
    marginBottom: 40,
  },
}))

export const WidgetWrapperInner = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

export const WidgetContent = styled.div<{ isRepaymentCompleted?: boolean; order?: number }>(
  ({ theme, isRepaymentCompleted, order }) => ({
    paddingRight: 10,
    order: isRepaymentCompleted ? order : 'inherit',
    minWidth: '50%',

    '&:last-of-type': {
      paddingRight: 0,
      marginLeft: isRepaymentCompleted ? 'auto' : 0,
      textAlign: isRepaymentCompleted ? 'right' : 'left',
    },

    [`${theme.media.md}`]: {
      order: 'inherit',
      minWidth: '25%',

      '&:last-of-type': {
        marginLeft: 'auto',
        textAlign: 'right',
      },
    },
  })
)

export const WidgetTitle = styled(Headline20)(({ theme }) => ({
  margin: '8px 0 16px',

  [`${theme.media.md}`]: {
    lineHeight: '24px',
    margin: '8px 0 24px',
  },
}))

export const WidgetDesc = styled(Headline14)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: theme.color['text/secondary'],
  fontWeight: 500,

  [`${theme.media.md}`]: {
    fontWeight: 600,
  },
}))

export const PaymentButton = styled(Button)(({ theme }) => ({
  height: 40,

  [`${theme.media.md}`]: {
    height: 56,
  },
}))

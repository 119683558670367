import React from 'react'
import { ApiStatus } from '@/core'
import { PaymentSchedule } from './containers/PaymentSchedule'
import { PaymentScheduleShimmer } from './components/PaymentScheduleShimmer'
import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { NextPaymentShimmer } from './components/NextPaymentShimmer'
import { Payment } from './containers/Payment'
import { InfoPlate } from './components/InfoPlate'

export interface MFODetailsProps {
  contract: GetContractInfoMFOModel.Contract
  status: ApiStatus
  retry: () => void
}

export const MFODetails = ({ contract, status, retry }: MFODetailsProps) => {
  if (status === ApiStatus.Pending)
    return (
      <>
        <PaymentScheduleShimmer />
        <NextPaymentShimmer />
      </>
    )

  const isShowPaymentSchedule =
    contract.guiStatus !== GetContractInfoMFOModel.GuiStatus.NoPaymentSchedule

  const isNoPaymentSchedule =
    contract.guiStatus === GetContractInfoMFOModel.GuiStatus.NoPaymentSchedule

  const isClosed = contract.status === GetContractInfoMFOModel.StatusCode.Closed

  return (
    <>
      {isShowPaymentSchedule && <PaymentSchedule />}
      {isNoPaymentSchedule && !isClosed && <InfoPlate />}
      {!isNoPaymentSchedule && !isClosed && <Payment contract={contract} retry={retry} />}
    </>
  )
}

import React, { useState } from 'react'
import {
  PaymentWrapper,
  PaymentTitleWrapper,
  PaymentTitle,
  DateWrapper,
  DateTitle,
  DateIconWrapper,
  PaymentDate,
  Info,
  TooltipBody,
  DateDesc,
} from './styled'
import { formatDate } from '@/utils/formatDate'
import { AboutSecurityIcon } from '@platform-ui/icons/AboutSecurityIcon'
import { pluralize } from '@/utils/pluralize'
import { observer } from 'mobx-react-lite'
import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { NextPaymentWidget } from '../../components/NextPaymentWidget'
import { PaymentModal } from '@/features/product-lists/containers/ProductListMFO/PaymentModal'
import { Tooltip } from '@platform-ui/components'

const TITLES = ['день', 'дня', 'дней']

const toCurrentFormatDate = (date: string): string => {
  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'D MMMM')
}

const toNextDayFormatDate = (date: string): string => {
  const parsedDate = date?.slice(0, 10)

  if (!parsedDate) {
    return '-'
  }

  const [year, month, day] = parsedDate?.split('-').map((item) => Number(item))
  const nextDate = new Date(year, month, day + 1)
  return formatDate(nextDate, 'D MMMM')
}

const getDaysBeforePayment = (date: string): number => {
  const currentDate = new Date()
  const paymentDate = new Date(date)
  const timeDiff = Math.abs(currentDate.getTime() - paymentDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  return diffDays
}

const getPaymentTitle = ({
  guiStatus,
  autoCins,
  nearestDate,
}: {
  guiStatus: GetContractInfoMFOModel.GuiStatus
  autoCins: boolean
  nearestDate: string
}) => {
  if (autoCins && guiStatus === GetContractInfoMFOModel.GuiStatus.PartialEarlyRepayment) {
    return 'Ежемесячный платёж поступил'
  }

  if (!autoCins && guiStatus === GetContractInfoMFOModel.GuiStatus.PartialEarlyRepayment) {
    return 'Достаточно средств для частичного погашения кредита'
  }

  if (guiStatus === GetContractInfoMFOModel.GuiStatus.EarlyRepayment) {
    return 'Достаточно средств для полного погашения кредита'
  }

  if (guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentInAdvance) {
    return 'Платёж поступил'
  }

  if (guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday) {
    return `Платёж от ${toCurrentFormatDate(nearestDate)} успешно списан`
  }

  return 'Следующий платёж'
}

const getDeductedPayment = ({
  earlyPaymentType,
  earlyPayment,
  nearestPayment,
}: {
  earlyPaymentType: number | undefined
  earlyPayment: number
  nearestPayment: number
}): number => {
  switch (earlyPaymentType) {
    case 0:
      return earlyPayment
    case 1:
      return nearestPayment + earlyPayment
    default:
      return nearestPayment
  }
}

const getPaymentDateTitle = ({
  guiStatus,
  nearestDate,
}: {
  guiStatus: GetContractInfoMFOModel.GuiStatus
  nearestDate: string
}) => {
  if (
    guiStatus === GetContractInfoMFOModel.GuiStatus.PartialEarlyRepayment ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.EarlyRepayment ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentInAdvance ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday
  ) {
    return `Информация в системах кредитора будет обновлена ${toNextDayFormatDate(nearestDate)}`
  }

  return 'Дата платежа'
}

const getPaymentDateByStatus = ({
  wallet,
  guiStatus,
  status,
  nearestDate,
  debtDays,
  autoCins,
  monthlyPayment,
}: {
  wallet: number
  guiStatus: GetContractInfoMFOModel.GuiStatus
  status: GetContractInfoMFOModel.StatusCode
  nearestDate: string
  debtDays: number
  autoCins: boolean
  monthlyPayment: number
}): string => {
  if (autoCins && wallet >= monthlyPayment) {
    return 'Комфортный платёж внесён'
  } else {
    if (
      guiStatus === GetContractInfoMFOModel.GuiStatus.TooEarlyToPay ||
      guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentStronglyRecommended ||
      guiStatus === GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_TooEarlyToPay ||
      guiStatus ===
        GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_PaymentStronglyRecommended ||
      guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_TooEarlyToPay ||
      guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_PaymentStronglyRecommended
    ) {
      const formattedDate = toCurrentFormatDate(nearestDate)
      const daysBeforePayment = getDaysBeforePayment(nearestDate)
      return `${formattedDate}, через ${daysBeforePayment} ${pluralize(daysBeforePayment, TITLES)}`
    }

    if (
      guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDay ||
      guiStatus === GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_PaymentDay ||
      guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_PaymentDay
    ) {
      return 'Платёж сегодня'
    }

    if (
      guiStatus === GetContractInfoMFOModel.GuiStatus.OverduePayment ||
      status === GetContractInfoMFOModel.StatusCode.Demanded
    ) {
      return `Платёж просрочен на ${debtDays} ${pluralize(debtDays, TITLES)}`
    }
  }

  return ''
}

export interface PaymentProps {
  contract: GetContractInfoMFOModel.Contract
  retry: () => void
}

export const Payment = observer(({ contract, retry }: PaymentProps) => {
  const {
    debtDays,
    guiStatus,
    status,
    wallet,
    nearestPayment,
    nearestPaymentDetails,
    nearestDate,
    earlyPaymentType,
    remainingDebt,
    autoCins,
    type,
  } = contract

  const isNotSet = guiStatus === GetContractInfoMFOModel.GuiStatus.NotSet

  const isOverduePayment =
    guiStatus === GetContractInfoMFOModel.GuiStatus.OverduePayment ||
    status === GetContractInfoMFOModel.StatusCode.Demanded

  const isPaymentDay =
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_PaymentDay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_PaymentDay

  const isShowAttentionIcon = isPaymentDay || isOverduePayment

  const bgColorForIcon = isOverduePayment ? 'icons/colors/error' : 'surface/attention'

  const deductedPayment = getDeductedPayment({
    earlyPaymentType,
    earlyPayment: nearestPaymentDetails?.EarlyPayment || 0,
    nearestPayment,
  })

  const isRepaymentCompleted =
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentInAdvance ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.EarlyRepayment ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PartialEarlyRepayment

  const isPaymentDebitedToday = guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday

  const isPaymentTooltipVisible =
    autoCins && wallet >= nearestPaymentDetails?.MonthlyPayment && wallet < remainingDebt

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {!isNotSet && (
        <PaymentWrapper>
          <PaymentTitleWrapper>
            <PaymentTitle color="text/main">
              <span>{getPaymentTitle({ guiStatus, autoCins, nearestDate })}</span>
              {isPaymentTooltipVisible && (
                <Tooltip
                  title={
                    <TooltipBody>
                      Комфортный ежемесячный платёж уже внесен. Это сумма, которую вы можете внести
                      для оплаты ежемесячного платежа по графику рассрочки
                    </TooltipBody>
                  }
                  placement="bottom-end"
                >
                  <Info />
                </Tooltip>
              )}
            </PaymentTitle>
          </PaymentTitleWrapper>

          <DateWrapper>
            {isShowAttentionIcon && (
              <DateIconWrapper backgroundColor={bgColorForIcon}>
                <AboutSecurityIcon />
              </DateIconWrapper>
            )}

            <div>
              {isPaymentDay ? (
                <DateDesc color="text/main">Платёж сегодня</DateDesc>
              ) : (
                <>
                  <DateTitle>{getPaymentDateTitle({ guiStatus, nearestDate })}</DateTitle>
                  <PaymentDate color="text/main">
                    {getPaymentDateByStatus({
                      wallet,
                      guiStatus,
                      status,
                      nearestDate,
                      debtDays,
                      autoCins,
                      monthlyPayment: nearestPaymentDetails?.MonthlyPayment,
                    })}
                  </PaymentDate>
                </>
              )}
            </div>
          </DateWrapper>
        </PaymentWrapper>
      )}

      <NextPaymentWidget
        deductedPayment={deductedPayment}
        wallet={wallet}
        remainingDebt={remainingDebt}
        isRepaymentCompleted={isRepaymentCompleted}
        isPaymentDebitedToday={isPaymentDebitedToday}
        buttonClick={() => {
          setIsOpen(true)
        }}
        isEarlyRepayment={guiStatus === GetContractInfoMFOModel.GuiStatus.EarlyRepayment}
      />

      {isOpen && (
        <PaymentModal
          contractId={contract.contractId}
          amount={remainingDebt - wallet > 0 ? remainingDebt - wallet : 0}
          isOpen={isOpen}
          onClose={() => {
            retry()
            setIsOpen(false)
          }}
          contractType={type}
          recipient={contract.partnerInfo.PartnerName || ''}
        />
      )}
    </>
  )
})

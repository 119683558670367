import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DialogPaymentSchedule } from '../DialogPaymentSchedule'
import { PaymentSchedulePlate } from '../../components/PaymentSchedulePlate'

export const PaymentSchedule = observer(() => {
  const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState(false)

  const onOpen = () => {
    setIsOpenInDevelopmentModal(true)
  }

  const onClose = (): void => {
    setIsOpenInDevelopmentModal(false)
  }

  return (
    <>
      <PaymentSchedulePlate onOpen={onOpen} />
      <DialogPaymentSchedule isOpen={isOpenInDevelopmentModal} onClose={onClose} />
    </>
  )
})

import React from 'react'
import {
  MFOSliderWrapper,
  MFOSlide,
  MFOSlideTitle,
  MFOSlideDesc,
  ProgressBarTextWrapper,
  ProgressBarText,
  ProgressBar,
  ProgressBarInner,
  PartnerIcon,
} from './styled'
import { Currency } from '@/components/Currency'
import { ApiStatus } from '@/core'
import { observer } from 'mobx-react-lite'
import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { MFOSliderShimmer } from './components/MFOSliderShimmer'

export interface MFOSliderProps {
  contract: GetContractInfoMFOModel.Contract
  status: ApiStatus
}

const getBgColor = (
  guiStatus: GetContractInfoMFOModel.GuiStatus,
  contractStatus: GetContractInfoMFOModel.StatusCode
): string => {
  if (
    guiStatus === GetContractInfoMFOModel.GuiStatus.TooEarlyToPay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentInAdvance ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_TooEarlyToPay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_TooEarlyToPay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.EarlyRepayment ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PartialEarlyRepayment ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday
  ) {
    return 'linear-gradient(0.34deg, #0082C2 0.3%, #00AAFF 145.85%)'
  }

  if (
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentStronglyRecommended ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDay ||
    guiStatus ===
      GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_PaymentStronglyRecommended ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PayOnlyEarlypayment_PaymentDay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_PaymentStronglyRecommended ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.BothPayment_PaymentDay
  ) {
    return 'products/loanOrange'
  }

  if (
    guiStatus === GetContractInfoMFOModel.GuiStatus.OverduePayment ||
    contractStatus === GetContractInfoMFOModel.StatusCode.Demanded
  ) {
    return 'products/loanRed'
  }

  return 'products/undefined'
}

export const MFOSlider = observer(({ contract, status }: MFOSliderProps) => {
  if (status === ApiStatus.Pending) {
    return <MFOSliderShimmer />
  }

  const { guiStatus, status: contractStatus, amount, principal, partnerInfo } = contract
  const bgColor = getBgColor(guiStatus, contractStatus)

  const iconUrl = partnerInfo?.LogoUrl || ''

  return (
    <MFOSliderWrapper>
      <MFOSlide bgColor={bgColor}>
        <ProgressBarTextWrapper>
          <div>
            <MFOSlideTitle>
              <Currency amount={principal} isShowPrecisionWithNullCents />
            </MFOSlideTitle>
            <MFOSlideDesc>Осталось выплатить без процентов</MFOSlideDesc>
          </div>
          {iconUrl && <PartnerIcon src={iconUrl} />}
        </ProgressBarTextWrapper>
        <div>
          <ProgressBarTextWrapper>
            <ProgressBarText>
              Выплачено <Currency amount={amount - principal} />
            </ProgressBarText>
            <ProgressBarText>
              из <Currency amount={amount} />
            </ProgressBarText>
          </ProgressBarTextWrapper>
          <ProgressBar>
            <ProgressBarInner completed={((amount - principal) * 100) / amount} />
          </ProgressBar>
        </div>
      </MFOSlide>
    </MFOSliderWrapper>
  )
})
